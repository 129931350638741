import { Contact } from "./components/Contact";
import { Home } from "./components/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
      path: '/contact',
      element: <Contact />
  }
];

export default AppRoutes;
