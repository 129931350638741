import React, { Component } from 'react';

export class Contact extends Component {
    static displayName = Contact.name;  

  render() {
    return (
      <div>
        <h1>Contact</h1>

            <p>Ask more about individual training solutions:<br />
                <b>
                    <a href="mailto:zealhook.solutions@gmail.com">Send Email</a>
                </b>
            </p>

      </div>
    );
  }
}
