import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';

export default class App extends Component {
  static displayName = App.name;

  render() {
      return (
          <div>
              <Layout>
                  <Routes>
                      {AppRoutes.map((route, index) => {
                          const { element, ...rest } = route;
                          return <Route key={index} {...rest} element={element} />;
                      })}
                  </Routes>

              </Layout>
              <footer className="border-top footer text-muted bg-dark">
                  <div
                      className="container text-light"
                      style={{
                          display: "inline-flex",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                          marginTop: "16px"
                      }}>
                      <div>
                          <p>&copy; 2024 - Zealhook Solutions</p>
                      </div>
                      <div style={{ position: "absolute", right: "16px" }}>
                          <img src="/favicon.ico" alt=""
                              width="30"
                              height="30" />
                      </div>                     
                  </div>                  
              </footer>
        </div>      
    );
  }
}
