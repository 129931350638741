import React, { Component } from 'react';
import { Container, Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
      return (
          <header>
              <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 bg-dark">
                  <Container style={{ display: "inline-flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ display: "inline-flex", alignItems: "center" }}>
                          <img src="/favicon.ico" alt=""
                              width="30"
                              height="30"
                              style={{ maxHeight: "35px" }} />{' '}
                          <NavbarBrand light="true" tag={Link} to="/">
                              <div style={{ wordBreak: "break-word", marginLeft: "6px" }}>Zealhook Solutions</div>
                          </NavbarBrand>
                      </div>                    
                      <NavbarToggler style={{ float: "inline-end" }} onClick={this.toggleNavbar} className="mr-2 bg-light" light="true" />                      
                </Container>
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar light="true">
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-light" to="/contact">Contact</NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
          </header>
    );
  }
}
