import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
      return (
          <div className="info-container" style={{ marginBottom: "100px" }}>
            <h1>About Zealhook Solutions</h1>
              <p>Zealhook Solutions is offering individual athlete training:</p>
              <ul className="bg-dark">
                <li className="text-light">Sensory training</li>
                <li className="text-light">Game analysis & Game IQ coaching</li>
                <li className="text-light">Ice Hockey coaching</li>
                <li className="text-light">Sport physiotherapy</li>
              </ul>
              <div>
                  <img width="50%" src="https://images.unsplash.com/photo-1632649177901-89d46eec248f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aWNlJTIwaG9ja2V5JTIwdHJhaW5pbmd8ZW58MHx8MHx8fDA%3D" alt="content" />
                  <br />
              </div>
            <b>Got interested?</b>
            <p>
                <a href="/contact">Contact us</a> and we'll find the best Solution for you.
            </p>
          </div>
    );
  }
}
